<template>
  <div>
    <b-row align-h="center">
      <b-col>
        <b-card header="Add Notification">
          <validation-observer
            v-slot="{ handleSubmit }"
          >
            <b-form @submit.prevent="handleSubmit(addNotification)">
              <b-form-row>
                <b-col lg="6">
                  <validation-provider
                    #default="validationContext"
                    name="Title"
                    rules="required"
                  >
                    <b-form-group
                      label="Title"
                      label-for="title"
                    >
                      <b-form-input
                        id="title"
                        v-model="notificationData.title"
                        placeholder="Enter Notification Title"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col lg="6">
                  <validation-provider
                    #default="validationContext"
                    name="Body"
                    rules="required"
                  >
                    <b-form-group
                      label="Body"
                      label-for="body"
                    >
                      <b-form-input
                        id="body"
                        v-model="notificationData.body"
                        placeholder="Enter Notification Body"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-form-row>

              <b-form-row v-if="!notificationData.sendsImmediately">
                <b-col lg="6">
                  <validation-provider
                    #default="validationContext"
                    name="Sends At"
                    rules="required"
                    :detect-input="false"
                  >
                    <b-form-group
                      label="Sends At"
                      label-for="sends-at"
                    >
                      <flat-pickr
                        v-model="notificationData.sendsAt"
                        class="form-control"
                        required
                        :config="{ enableTime:true,dateFormat: 'Y-m-d H:i', minDate: 'today'}"
                        @input="validationContext.validate(notificationData.sendsAt)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-form-row>

              <b-form-group
                label="Sends Immediately"
                label-for="sends-immediately"
              >
                <b-form-checkbox
                  id="sends-immediately"
                  v-model="notificationData.sendsImmediately"
                  :value="1"
                  :unchecked-value="0"
                  required
                />
              </b-form-group>

              <b-button
                type="submit"
                variant="primary"
              >
                Add
              </b-button>
              <back />
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import flatPickr from 'vue-flatpickr-component'
import formValidation from '@core/comp-functions/forms/form-validation'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import Back from '@/common/components/common/Back.vue'

export default {
  name: 'AddNotification',
  components: {
    flatPickr,
    Back,
  },
  data() {
    return {
      notificationData: {
        sendsAt: new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().replace(/T/, ' ').replace(/\..+/, '')
          .slice(0, -3),
        sendsImmediately: 0,
      },
    }
  },
  setup() {
    const { successfulOperationAlert, showErrors } = handleAlerts()
    const {
      getValidationState,
    } = formValidation()
    return {
      successfulOperationAlert, showErrors, getValidationState,

    }
  },
  methods: {
    addNotification() {
      this.$portalUsers.post('/internalops/notification/send/all-users ', this.notificationData).then(() => {
        this.successfulOperationAlert('Notification is added successfully')
        this.$router.push({ name: 'add-notifications' })
      }).catch(err => {
        this.showErrors(err.response.data.errors)
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
